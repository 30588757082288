import React, { useMemo } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { SECTIONS } from './OurClinicPage';

const AWS_URL =
  'https://baltean-ortodontics-images.s3.eu-north-1.amazonaws.com';

const CarouselComponent = ({ currentSection }) => {
  const clinicImages = useMemo(() => {
    const images = [];
    for (let i = 1; i <= 35; i++) {
      // Create the image object with `original` and `thumbnail` properties
      images.push({
        original: `${AWS_URL}/clinica/${i}.jpg`,
        thumbnail: `${AWS_URL}/clinica/${i}.jpg`,
      });
    }
    return images;
  }, []);

  const labImages = useMemo(() => {
    const images = [];
    for (let i = 1; i <= 3; i++) {
      // Create the image object with `original` and `thumbnail` properties
      images.push({
        original: `${AWS_URL}/laborator/${i}.jpg`,
        thumbnail: `${AWS_URL}/laborator/${i}.jpg`,
      });
    }
    return images;
  }, []);

  const conferencesImages = useMemo(() => {
    const images = [];
    for (let i = 1; i <= 5; i++) {
      // Create the image object with `original` and `thumbnail` properties
      images.push({
        original: `${AWS_URL}/sala_de_conferinte/${i}.jpg`,
        thumbnail: `${AWS_URL}/sala_de_conferinte/${i}.jpg`,
      });
    }
    return images;
  }, []);

  const receptionImages = useMemo(() => {
    const images = [];
    for (let i = 1; i <= 3; i++) {
      // Create the image object with `original` and `thumbnail` properties
      images.push({
        original: `${AWS_URL}/receptie/${i}.webp`,
        thumbnail: `${AWS_URL}/receptie/${i}.webp`,
      });
    }
    return images;
  }, []);

  const sectionImages = useMemo(() => {
    switch (currentSection) {
      case SECTIONS.CLINIC:
        return clinicImages;

      case SECTIONS.RECEPTION:
        return receptionImages;

      case SECTIONS.LAB:
        return labImages;

      case SECTIONS.CONFERENCES:
        return conferencesImages;

      default:
        return receptionImages;
    }
  }, [
    currentSection,
    clinicImages,
    labImages,
    receptionImages,
    conferencesImages,
  ]);

  return (
    <div
      id='gallery'
      style={{
        backgroundColor: 'aliceblue',
        backdropFilter: 'blur(24px)',
        borderColor: 'divider',
        boxShadow:
          '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
      }}
    >
      <ImageGallery items={sectionImages} autoPlay lazyLoad />
    </div>
  );
};

export default CarouselComponent;
